<template>
  <section
    v-if="step === 1"
    class="agent-complete-register-section"
  >
    <Alert
      :is-error-alert-open="isErrorAlertOpen"
      :error-alert-message="errorAlertMessage"
      @close="closeErrorAlert"
    />
    <div class="agent-complete-register-section__stepper">
      <div class="agent-complete-register-section__stepper__step">
        <span
          class="agent-complete-register-section__stepper__step__count active"
        >
          1
        </span>
        <div
          class="agent-complete-register-section__stepper__step__label active"
        >
          Form Pendaftaran
        </div>
      </div>
      <img
        class="agent-complete-register-section__stepper__divider"
        src="/images/arrow-stepper.svg"
        alt="arrow stepper"
      >
      <div class="agent-complete-register-section__stepper__step">
        <span
          class="agent-complete-register-section__stepper__step__count active"
        >
          2
        </span>
        <div class="agent-complete-register-section__stepper__step__label">
          Dokumen Pendukung
        </div>
      </div>
    </div>
    <h1 class="agent-complete-register-section__heading">
      Lengkapi Dokumen untuk Memenuhi Syarat Pendaftaran Agen
    </h1>
    <h2 class="agent-complete-register-section__subheading">
      Dokumen pendukung ini bersifat opsional apabila Anda mengisi maka akan mempercepat proses verifikasi pendaftaran agen
    </h2>
    <div class="agent-complete-register-section__registration">
      Nomor registrasi anda adalah:
      <div class="agent-complete-register-section__registration__number">
        A{{ agentId }}
      </div>
    </div>
    <img
      class="agent-complete-register-section__image"
      src="/images/complete-registration.svg"
      alt="complete registration"
    >
    <form class="agent-complete-register-section__form">
      <h1>Langkah Terakhir Pendaftaran</h1>
      <div class="agent-complete-register-section__form__download">
        <div
          class="agent-complete-register-section__form__download__count active"
        >
          1
        </div>
        <div class="agent-complete-register-section__form__download__text">
          Download
          <div
            class="agent-complete-register-section__form__download__text__link"
            @click="downloadDocument"
          >
            Dokumen & Syarat Ketentuan
            <img
              src="/images/ics_o_download.svg"
              alt="download icon"
            >
          </div>
        </div>
      </div>
      <div class="agent-complete-register-section__form__complete">
        <div
          class="agent-complete-register-section__form__complete__count active"
        >
          2
        </div>
        <div class="agent-complete-register-section__form__complete__text">
          Silahkan upload dokumen pendukung
        </div>
      </div>
      <div class="agent-complete-register-section__form__image-upload">
        <UploadImage
          v-model="form.agent_identity.value"
          placeholder="Upload KTP"
          label="KTP"
          :required="true"
          note="Maksimal: 5MB, Format: jpg, png. Rekomendasi Dimensi 2 : 4 (contoh: 200px X 800px)"
          error-message="Foto KTP tidak boleh melebihi dari 5 MB"
          format-note="Format .png .jpg .jpeg .webp maksimal 5MB"
          :is-error="form.agent_identity.isError"
          @input="(val) => onChangeImage('agent_identity', val)"
          @error="(msg) => openErrorAlert(msg)"
        />
      </div>
      <div class="agent-complete-register-section__form__image-upload">
        <UploadImage
          v-model="form.agent_location_front.value"
          placeholder="Upload Foto Depan Ruko/Kios/Rumah"
          label="Lokasi Tampak Depan"
          :required="true"
          note="Pastikan foto lokasi jelas dan tampak depan"
          error-message="Foto lokasi tampak depan tidak boleh melebihi dari 5 MB"
          format-note="Format .png .jpg .jpeg .webp maksimal 5MB"
          :is-error="form.agent_location_front.isError"
          @input="(val) => onChangeImage('agent_location_front', val)"
          @error="(msg) => openErrorAlert(msg)"
        />
      </div>
      <div class="agent-complete-register-section__form__image-upload">
        <UploadImage
          v-model="form.agent_location_in.value"
          placeholder="Upload Lokasi Tampak Dalam"
          label="Lokasi Tampak Dalam"
          :required="true"
          note="Maksimal: 5MB, Format: jpg, png. Rekomendasi Dimensi 2 : 4 (contoh: 200px X 800px)"
          error-message="Foto lokasi tampak dalam tidak boleh melebihi dari 5 MB"
          format-note="Format .png .jpg .jpeg .webp maksimal 5MB"
          :is-error="form.agent_location_in.isError"
          @input="(val) => onChangeImage('agent_location_in', val)"
          @error="(msg) => openErrorAlert(msg)"
        />
      </div>
      <div class="agent-complete-register-section__form__button">
        <Button
          text="Kirim"
          :disabled="decideDisableButton"
          @click="submit"
        />
      </div>
    </form>
    <div class="agent-complete-register-section__info">
      Untuk info selanjutnya akan kami hubungi melalui nomor 02150862088
      <br>
      <br>
      Jika masih ada pertanyaan Anda dapat Email ke
      <a href="mailto:npos@lionparcel.com">npos@lionparcel.com</a> atau hubungi
      whatsapp resmi tim registrasi lion parcel melalui nomor WA 
      <span 
        class="linkSpan" 
        @click="triggerToCS(6282122814242)"
      >
        082122814242
      </span> 
      atau 
      <span 
        class="linkSpan" 
        @click="triggerToCS(6282122814040)"
      >
        082122814040
      </span>
    </div>
  </section>
  <section
    v-else
    class="agent-success-register-section"
  >
    <img
      class="agent-success-register-section__checklist-icon"
      src="/images/success-register.svg"
      alt="success register icon"
    >
    <h1 class="agent-success-register-section__heading">
      Selamat Pengajuan Pendaftaran Agen Telah Berhasil
    </h1>
    <h2 class="agent-success-register-section__subheading">
      Proses pengajuan anda sedang kami verifikasi oleh pihak terkait. Proses
      verifikasi memerlukan waktu maksimal 7 hari kerja.
    </h2>
    <div class="agent-success-register-section__registration">
      Nomor registrasi anda adalah:
      <div class="agent-success-register-section__registration__number">
        A{{ agentId }}
      </div>
    </div>
    <div class="agent-success-register-section__info">
      Untuk info selanjutnya akan kami hubungi melalui nomor 02150862088
      <br>
      <br>
      Jika masih ada pertanyaan Anda dapat Email ke
      <a href="mailto:npos@lionparcel.com">npos@lionparcel.com</a> atau hubungi
      whatsapp resmi tim registrasi lion parcel melalui nomor WA 
      <span 
        class="linkSpan" 
        @click="triggerToCS(6282122814242)"
      >
        082122814242
      </span> 
      atau 
      <span 
        class="linkSpan" 
        @click="triggerToCS(6282122814040)"
      >
        082122814040
      </span>
    </div>
    <div class="agent-success-register-section__button">
      <Button
        text="Ke Halaman Utama"
        type-class="outline-red"
        @click="backToHome"
      />
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import Button from '@/components/new-button/Button.vue';
import UploadImage from '@/pages/new-agent/components/UploadImage';
import AgentUseCase from '@/pages/new-agent/app/usecase/agentRegister';
import Alert from '../../components/Alert.vue';
import mixinAlert from '../../mixins/mixinAlert';

export default {
  components: { Button, UploadImage, Alert },
  mixins: [mixinAlert],
  data() {
    return {
      agentId: null,
      step: 1,
      form: {
        agent_identity: {
          isError: false,
          value: null,
        },
        agent_location_front: {
          isError: false,
          value: null,
        },
        agent_location_in: {
          isError: false,
          value: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['AGENT_ID']),
    decideDisableButton() {
      return (
        !this.form.agent_identity.value
        && !this.form.agent_location_front.value
        && !this.form.agent_location_in.value
      );
    },
  },
  beforeMount() {
    this.agentId = this.AGENT_ID;
    this.checkIdAgent();
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  },
  methods: {
    triggerToCS(no) {
      window.open(`https://wa.me/${no}`);
    },
    checkIdAgent() {
      if (this.agentId === null) {
        this.$router.push('/agen');
      } else {
        this.$gtag.event('conversion', {
          send_to: 'AW-376237548/IOUrCIj-io0CEOzbs7MB',
        });
      }
    },
    downloadDocument() {
      window.open(
        'https://storage.googleapis.com/internal-website/agent/Syarat%20&%20Ketentuan%20Agen%20Lion%20Parcel.pdf',
      );
    },
    onChangeImage(key, value) {
      if (value) {
        if (value.size > 5 * 1024 * 1024) {
          this.form[key] = {
            value: null,
            isError: true,
          };
        } else {
          this.form[key] = {
            value,
            isError: false,
          };
        }
      }
    },
    async submit() {
      const payload = new FormData();
      let keyString = '';

      const forms = Object.keys(this.form).map(key => this.form[key].value && { [key]: this.form[key].value }).filter(form => form);

      forms.forEach((form) => {
        const key = Object.keys(form)[0];
        payload.append(key, form[key]);
        keyString += `${key},`;
      });

      payload.append('key', keyString.replace(/,\s*$/, ''));

      try {
        const res = await AgentUseCase.submitSupportDocument({
          agent_id: this.AGENT_ID,
          files: payload,
        });
        if (res?.data) {
          this.step = 2;
        }
        if (res?.err) {
          this.openErrorAlert(res.err);
        }
      } catch (err) {
        this.openErrorAlert(err);
      }
    },
    backToHome() {
      window.location.href = '/';
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";

.linkSpan {
  cursor: pointer;
  color: $color-link-blue;
}
.linkSpan:hover {
  color: $color-indigo-dark;    
}
</style>
