<template>
  <section class="upload-image-section">
    <span class="upload-image-section__label">{{ label }}</span>
    <div
      v-if="!value"
      class="upload-image-section__box"
      :class="isError && 'error'"
      @click="uploadFile"
    >
      <img
        class="upload-image-section__box__upload-icon"
        src="/images/UploadIcon.svg"
        alt="upload icon"
      >
      <span class="upload-image-section__box__placeholder">{{
        placeholder
      }}</span>
      <span class="upload-image-section__box__note">{{ formatNote }}</span>
    </div>
    <div
      v-else
      class="upload-image-section__preview"
    >
      <img
        class="upload-image-section__preview__image"
        :src="getPreviewImage()"
        alt="image preview"
      >
      <div
        class="upload-image-section__preview__edit"
        @click="uploadFile"
      >
        <span>Ubah Foto</span>
      </div>

      <img
        class="upload-image-section__preview__trash"
        src="/images/trashIcon.svg"
        alt="trash icon"
        @click="removeImage"
      >
    </div>
    <div
      v-if="isError"
      class="upload-image-section__note error"
    >
      {{ errorMessage }}
    </div>
    <div
      v-else
      class="upload-image-section__note"
    >
      {{ note }}
    </div>
    <input
      ref="input-file"
      style="display: none"
      type="file"
      :accept="imageFormats.join(', ')"
      @change="onChangeFile"
    >
  </section>
</template>
<script>
import { isSsr } from '../../../helpers/DetectSSR';

export default {
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: isSsr() ? String : File,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    formatNote: {
      type: String,
      default: 'Format .png .jpg maksimal 16MB',
    },
    note: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    imageFormats: {
      type: Array,
      default: () => ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
    },
  },
  methods: {
    uploadFile() {
      this.$refs['input-file'].value = null;
      this.$refs['input-file'].click();
    },
    onChangeFile(e) {
      if (e.target.files[0] && this.validateImageFormat(e.target.files[0])) {
        this.$emit('input', e.target.files[0]);
      } else {
        this.$emit('error', 'Format gambar tidak sesuai');
      }
    },
    getPreviewImage() {
      return window.URL.createObjectURL(this.value);
    },
    removeImage() {
      this.$emit('input', null);
    },
    validateImageFormat(file) {
      return this.imageFormats
        .map(format => !!file.type.match(format))
        .includes(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.upload-image-section {
  &__label {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $color-base-text;
  }
  &__box {
    width: 514px;
    height: 146px;
    border: 1.5px dashed $color-gray-pensive-5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 28px 0;

    &.error {
      border: 1.5px dashed $color-red-5;
    }

    @include for-size(mobile) {
      width: 100%;
      height: 100%;
      padding: 21px 0;
    }

    &__upload-icon {
      width: 42px;
      height: 42px;
    }

    &__placeholder {
      margin-top: 8px;
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: $color-base;
    }

    &__note {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $color-gray-shade;
    }
  }

  &__preview {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__image {
      width: 348px;
      height: 104px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 24px;

      @include for-size(mobile) {
        width: 122px;
        height: 104px;
      }
    }

    &__edit {
      width: 94px;
      height: 36px;
      background: $color-white;
      border: 1px solid $color-gray-pensive-5;
      border-radius: 6px;
      margin-right: 24px;
      cursor: pointer;

      & span {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: $color-gray-shade;
      }
    }

    &__trash {
      cursor: pointer;
    }
  }

  &__note {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-gray-shade;

    &.error {
      text-align: center;
      align-items: center;
      color: $color-base;
    }
  }
}
</style>
